import { getAuthState } from '@sparky/framework';
import { TsgInstances } from '@sparky/framework/build/types/framework/indexedArrays';

const MFE_PROPERTY_NAME = 'tenant_appsec_feature_flag_sre';

// check MFE property store to check if the tenant has the feature enabled
export function checkMfePSIfEnabled(): boolean {
  const authState = getAuthState();
  const authStateProperties = authState?.properties;
  const appEnabledProp = authStateProperties?.get(MFE_PROPERTY_NAME);
  return !!appEnabledProp?.enabled;
}

const APP_SEC_ID = 'add_app_sec';

type EntitlementType = {
  app_id: string;
  license_type: string;
  license_expiration?: string;
};

export function checkHasLicense(): boolean {
  const authState = getAuthState();

  const instances: Readonly<TsgInstances> = authState?.instances;
  if (instances?.length > 0) {
    for (let inst = 0, instLen = instances.length; inst < instLen; inst++) {
      const appId = instances[inst]?.app_id;
      const allowedApps = new Set([
        'prisma_access_edition',
        'prisma_access_edition_onprem',
        'prisma_access_panorama',
      ]);

      // only check license for prisma access app_ids
      if (!allowedApps.has(appId)) {
        continue;
      }

      const entitlements: EntitlementType[] = instances?.get(appId)?.entitlements;

      if (entitlements) {
        for (let i = 0, len = entitlements.length; i < len; i++) {
          const lic = entitlements[i];

          if (lic.app_id === APP_SEC_ID) {
            return true;
          }
        }
      }
    }
  }

  return false;
}
